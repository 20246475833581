<template>
  <router-view/>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
  },
  methods: {},
};
</script>
